import React, { useEffect, useState, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faPlus, faTimes, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { MediaImageUpload, MediaList, MultipleMediaUpload, productEdit } from "../../service/apiService";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ImageGalary from '../../assets/images/galary.jpg';
import { Link } from "react-router-dom";
import NoData from "../../assets/images/9264885-removebg.png"

const ProductEditImageMedia = ({ productId }) => {



  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImageUrls, setSelectedImageUrls] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.stylefitz.in/api/files/";
  const [disabled, setDisabled] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [activeTab, setActiveTab] = useState('gallery');
  const [modalMessage, setModalMessage] = useState({ type: '', text: '' });
  const [tempPriewImage, settempPriewImage] = useState([]);
  console.log(tempPriewImage)

  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);



  // get the  data  to display the images 
  useEffect(() => {
    if (token) {
      const fetchImages = async () => {
        try {
          const response = await MediaList(token);
          const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
          setImageUrl(imageUrls);
          const storedProductImages = productImages;
          if (storedProductImages) {
            setProductImages(storedProductImages);
          }
          else {
            alert("Error fetching images: from the api ");
          }
        } catch (error) {
          console.error("Error fetching images:", error);
        }
      };
      const getProductImages = async () => {
        if (productId) {
          const productImageList = await productEdit(token, productId);
          console.log(productImageList.data)
          settempPriewImage(productImageList.data.ProductMedia);
        }
      };
      fetchImages();
      getProductImages();
    }
  }, [token, productId, productImages]);


  // selecting  the images from the model
  const handleImageClick = (index) => {
    const selectedIndex = selectedImages.indexOf(index);
    if (selectedIndex === -1) {
      setSelectedImages([...selectedImages, index]);
    } else {
      setSelectedImages(selectedImages.filter((item) => item !== index));
    }
  };


  //alert 
  const openalert = () => {
    toast.error("Please Fill The Product Form Data");
  };

  //function to delete the  selected Image 
  const handleDeleteImage = (index) => {
    const updatedProductImages = productImages.filter((_, i) => i !== index);
    console.log(updatedProductImages)
    setProductImages(updatedProductImages);
    const updatedSelectedImages = selectedImages.filter((item) => item !== index);
    setSelectedImages(updatedSelectedImages);
  };


  //delete the image while selecting for images while uploading from the device 
  const handleRemovePreviewImage = (index) => {
    const updatedPreviewImages = previewImages.filter((_, i) => i !== index);
    setPreviewImages(updatedPreviewImages);
    const updatedFiles = Array.from(selectedFile).filter((_, i) => i !== index);
    setSelectedFile(updatedFiles.length > 0 ? updatedFiles : null);
  };


  //Move image draggable
  const moveImage = useCallback(
    (fromIndex, toIndex) => {
      setSelectedImages((prevImages) => {
        const updatedImages = [...prevImages];
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);
        return updatedImages;
      });

      setListOrder((prevListOrder) => {
        const updatedListOrder = [...prevListOrder];
        const [movedOrder] = updatedListOrder.splice(fromIndex, 1);
        updatedListOrder.splice(toIndex, 0, movedOrder);
        return updatedListOrder;
      });
    },
    [selectedImages, listOrder]
  );



  ///Update the image to the api 
  const handleUpdateImage = async () => {
    const updatedImageUrls = selectedImages.map((index) => imageUrl[index]);
    const listOrderPayload = selectedImages.map((_, index) => index + 1);
    setSelectedImageUrls(updatedImageUrls);

    if (updatedImageUrls.length === 0 && productImages.length === 0) {
      setModalMessage({ type: 'error', text: "Please select at least one image" });
      return;
    }

    if (productImages.length + updatedImageUrls.length > 10) {
      setModalMessage({ type: 'error', text: "You can only upload a maximum of 10 images" });
      return;
    }


    const mediaList = await MediaImageUpload(
      token,
      productId,
      updatedImageUrls,
      listOrderPayload,
      0,
    );
    console.log(mediaList);
    if (mediaList.status_code === 200) {
      toast.success(mediaList.message);
      setModalMessage({ type: 'success', text: mediaList.message });
      setSelectedImageUrls([]);
      setSelectedImages([]);
      setModalIsOpen(false);

      const newProductImages = [...productImages, ...mediaList.data.product_images.map(img => AppUrl + img)];
      console.log(newProductImages)
      setProductImages(newProductImages);
    } else {
      toast.error('Something went wrong. Please try again');
    }
  };


  //For the model 
  const handleFileChange = (e) => {
    const files = e.target.files;
    console.log(files)
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const validFiles = Array.from(files).filter(file => allowedTypes.includes(file.type));
    if (validFiles.length !== files.length) {
      setModalMessage({ type: 'error', text: "Only JPEG, PNG, and WEBP formats are allowed" });
      setSelectedFile(null);
      setPreviewImages([]);
      return;
    }

    setSelectedFile(validFiles);

    const filePreviews = validFiles.map(file => URL.createObjectURL(file));
    setPreviewImages(filePreviews);
  };


  //for the model update 
  const handleMultiImageUpload = async () => {
    if (!selectedFile || selectedFile.length === 0) {
      setModalMessage({ type: 'error', text: "Please select images to upload" });
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("file_name", selectedFile[i]);
    }

    try {
      const response = await MultipleMediaUpload(token, formData);
      console.log("response", response);
      if (response.status_code === 200) {
        setModalMessage({ type: 'success', text: "Images uploaded successfully" });

        const newImageUrls = response.data.uploaded_files.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(prevUrls => [...prevUrls, ...newImageUrls]);
        setSelectedImages(prevSelectedImages => [...prevSelectedImages, ...Array(newImageUrls.length).keys()]);
        setSelectedFile(null);
        setPreviewImages([]);
      }
    } catch (error) {
      console.log(error);
      setModalMessage({ type: 'error', text: "Error uploading images" });
    }
  };



  const ItemTypes = {
    IMAGE: "image",
  };


  // DragImage Function
  const DraggableImage = ({
    id,
    index,
    imageUrl,
    moveImage,
    handleDeleteImage,
  }) => {
    const [{ isDragging }, ref] = useDrag({
      type: ItemTypes.IMAGE,
      item: { id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.IMAGE,
      hover: (item) => {
        if (item.index !== index) {
          moveImage(item.index, index);
        }
      },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => ref(drop(node))}
        className="position-relative mr-3 mb-3 draggable-image"
        style={{ opacity }}
      >
        <img
          src={imageUrl}
          className="img-fluid"
          alt={imageUrl}
          style={{ width: "200px", height: "200px" }}
        />
        <button
          className="btn btn-danger position-absolute top-0 end-0 delete-button"
          style={{ zIndex: "10", background: 'none', color: 'red', border: 'none' }}

          onClick={() => handleDeleteImage(id)}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div >
    );
  };

  return (
    <>

      <div className="container Image">

        <div className="card-body-image">

          {/* Blog Container */}
          <div className="blog-container">
            <h5><strong>Media Images
            </strong> </h5>
            <hr></hr>
            {/* First Row: Selected Images */}
            <div className=" container row selected-images-row">
              <div className="previwImages">
                {tempPriewImage?.length > 0 ? (
                  <div className="d-flex flex-wrap">
                    {tempPriewImage.map((item, i) => (
                      <div key={item.product_asset_id} className="position-relative mr-1 mb-1">
                        <img
                          src={AppUrl + item.product_image}
                          className="img-fluid"
                          alt={`Uploaded Image ${i + 1}`}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    ))}
                  </div>

                ) : <div className="d-flex flex-column align-items-center">
                  <img src={NoData} width={200} height={200} className="mb-0" alt="No Data" />
                  <Link onClick={openModal}>Click To Add Images</Link>
                  <br />
                </div>
                }
              </div>
            </div>
            <br />
            {/* Second Row: Preview and Media Image */}
            <div className="row preview-media-row">
              {/* Preview Section */}
              <div className="col-md-6">
                {selectedImages.length > 0 && (
                  <div className="New-previwImages">
                    <p>Preview</p>
                    <DndProvider backend={HTML5Backend}>
                      {selectedImages.map((index, i) => (
                        <DraggableImage
                          key={index}
                          id={index}
                          index={i}
                          imageUrl={AppUrl + imageUrl[index]}
                          moveImage={moveImage}
                          handleDeleteImage={handleDeleteImage}
                          className="draggable-image"
                        />
                      ))}
                    </DndProvider>
                  </div>
                )}
                <br />
              </div>
            </div>

            {/* Buttons */}
            {disabled ? (
              <>
                <div className="d-flex justify-content-center">
                  <img
                    className="btn btn-secondary ml-2 btn-sm"
                    style={{ textAlign: "center" }}
                    src={ImageGalary}
                    width={"50px"}
                    height={"50px"}
                    readOnly
                    onClick={openalert}
                    alt="Imgesss"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    <button
                      className="btn btn-primary ml-2 btn-sm"
                      style={{ textAlign: "center" }}
                      onClick={openModal}
                    >
                      <FontAwesomeIcon icon={faPlus} />&nbsp;
                      Add Images
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button
                      className="btn btn-primary ml-2 btn-sm"
                      style={{ textAlign: "center" }}
                      onClick={handleUpdateImage}
                    >
                      <FontAwesomeIcon icon={faCloudArrowUp} />&nbsp;
                      Upload Image
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Gallery"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "1500px",
            minWidth: "1500px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
            border: "2px solid #63E6BE",

          },
        }}
      >
        <div className="container" style={{ width: "100%" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Image Gallery
              </h2>
            </div>
            <div className="col-auto">
              <button
                onClick={closeModal}
                style={{
                  fontSize: "30px",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
              </button>
            </div>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'gallery' ? 'active' : ''}`} onClick={() => setActiveTab('gallery')}>Image Media Gallery</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`} onClick={() => setActiveTab('upload')}>Upload Files</button>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === 'gallery' && (
              <div className="tab-pane active">
                <div className="row">
                  {imageUrl.map((image, index) => (
                    <div key={index} className="col-md-3 mb-3">
                      <div className="image-container"
                        style={{
                          width: "250px",
                          height: "250px",
                          border: "1px solid #ddd",
                          padding: "5px",
                          borderRadius: "5px",
                          position: "relative",
                          backgroundColor: selectedImages.includes(index)
                            ? "rgba(0,0,0,0.1)"
                            : "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(index)}
                      >
                        <img
                          src={AppUrl + image}
                          className="img-fluid"
                          alt={image}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                        <input
                          type="checkbox"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            zIndex: "10",
                          }}
                          checked={selectedImages.includes(index)}
                          onChange={() => handleImageClick(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === 'upload' && (
              <div className="tab-pane active">
                <div className="row">
                  <div className="col">
                    <br />
                    <input type="file" multiple onChange={handleFileChange} style={{ display: 'block', marginBottom: '10px' }} />
                    {previewImages.length > 0 && (
                      <div className="mt-3">
                        <h5>Selected Images:</h5>
                        <div className="d-flex flex-wrap">
                          {previewImages.map((src, index) => (
                            <div key={index} className="mr-3 mb-3 position-relative">
                              <img
                                src={src}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                  border: "1px solid #ddd",
                                  padding: "5px",
                                }}
                              />
                              <button
                                className="btn btn-danger position-absolute top-0 end-0"
                                style={{ zIndex: "10" }}
                                onClick={() => handleRemovePreviewImage(index)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {modalMessage.text && (
                      <div className={`alert mt-3 ${modalMessage.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
                        {modalMessage.text}
                      </div>
                    )}
                    <button className="btn btn-primary mt-3" onClick={handleMultiImageUpload}>Upload Images</button>
                  </div>
                </div>
              </div>
            )}

          </div>
          <div className="col-auto d-flex justify-content-end">
            <button
              onClick={closeModal}
              style={{
                fontSize: "30px",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-end",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "5px",
                background: "#F1E1D9",
                cursor: "pointer",
              }}
            >
              Close Model
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductEditImageMedia;
