import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  getSectionData,
  updateSection,
  ClassificationDropDown,
  CampaignDropDown
} from "../../../../service/apiService";
import Select from "react-select";
import { toast } from "react-toastify";
import ImageGallery from "../../../../components/Product/ImageGallery";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch"

const SectionEditModel = ({ isOpen, onRequestClose, sectionId, onSuccess, sectionGroupId, limit }) => {
  const [editFormData, setEditFormData] = useState({});
  const [editAdsDropdown, setEditAdsDropdown] = useState([]);
  const [editSectionDropdownData, setEditSectionDropdownData] = useState([]);
  //single dropdown
  const [editSingleDropDown, setEditSingleDropDown] = useState([]);

  ///Image and vedio 
  const AppUrl = "https://folcon.stylefitz.in/api/files/";
  const [editSelectedImageMedia, setEditSelectedImageMedia] = useState([]);
  const [editSelectedVideoMedia, setEditSelectedVideoMedia] = useState([]);
  const [EditisImageGalleryOpen, setEditIsImageGalleryOpen] = useState(false);
  const [editCampaignData, seteditCampaignData] = useState([]);
  const [EditsVideoGalleryOpen, seteditIsVideoGalleryOpen] = useState(false);
  const [EditerrorMessage, setEditErrorMessage] = useState("");
  const [mapId, setMapId] = useState([]);
  const [section14ImgId, setSection14ImgId] = useState(null);


  const [sec14FormData, setSec14FormData] = useState([
    { "key_name": "TITLE_1", "key_value": "" },
    { "key_name": "DESC_1", "key_value": "" },
    { "key_name": "IMG_1", "key_value": "" },
    { "key_name": "TITLE_2", "key_value": "" },
    { "key_name": "DESC_2", "key_value": "" },
    { "key_name": "IMG_2", "key_value": "" },
    { "key_name": "TITLE_3", "key_value": "" },
    { "key_name": "DESC_3", "key_value": "" },
    { "key_name": "IMG_3", "key_value": "" },
    { "key_name": "TITLE_4", "key_value": "" },
    { "key_name": "DESC_4", "key_value": "" },
    { "key_name": "IMG_4", "key_value": "" }
  ]);



  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const editHandleImageMediaSelect = (mediaUrls) => {
    const newSelectedMedia = [...editSelectedImageMedia, ...mediaUrls].slice(0, 12);
    setEditSelectedImageMedia(newSelectedMedia);
    setEditIsImageGalleryOpen(false);
  };
  const handleVideoMediaSelect = (mediaUrls) => {
    setEditSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...editSelectedVideoMedia, ...mediaUrls]
    );
    seteditIsVideoGalleryOpen(false);
  };
  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setEditSelectedImageMedia(editSelectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setEditSelectedVideoMedia(editSelectedVideoMedia.filter((_, i) => i !== index));
    }
  };

  //  { getting section number }
  const extractSectionNumber = (input) => {
    const match = input.match(/section(\d+)_/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  //section id 
  const SectionId_Number = extractSectionNumber(sectionId);

  const token = localStorage.getItem("admin_token");
  const PageId = localStorage.getItem("pageid")


  // HandleChange
  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };



  if (editFormData.PRE_ORDER === 1 || editFormData.PRE_ORDER === "1") {
    console.log(true)
  } else {
    console.log(false)
  }

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //handle change for section 5
  const handle_Chnage_05_Text_Field = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Fetching  dropdown data when the edit model is open 
  useEffect(() => {
    if (isOpen) {
      EditfetchAdsDropdown();
      editFetchAdsDropdownSingle();
      editFetchClassificationDropdown();
      EdicampaignDropdown();
    }
  }, [isOpen]);


  // {function to get the sectionMapId }
  const getSectionMapId = (response) => {
    const sectionsData = response.sections_data;
    if (sectionsData && sectionsData.length > 0) {
      return sectionsData;
    }
    return null;
  }
  const clearData = () => {
    setEditSelectedImageMedia([])
    setEditSelectedVideoMedia([])
    setEditFormData({})
  }
  // Api Called to fill the data In the sections
  useEffect(() => {
    if (SectionId_Number) {
      const fetchSectionDetails = async () => {
        const response = await getSectionData(token, sectionGroupId, PageId);

        if (response.data) {
          const data = response.data;
          const transform = getSectionMapId(data)
          setMapId(transform)
          const ads = data.sections_data?.length ? data.sections_data : [];
          // All data
          let obj = {}
          let obj_section_14 = []
          if (SectionId_Number === 2) {
            obj_section_14 = ads?.filter((item) => {
              let obj = {}
              obj["key_name"] = item?.key_name;
              obj["key_value"] = item?.key_value;
              return obj;
            })
          } else {
            ads?.map((item) => {
              if (item?.key_name === "File" && item?.key_value) {
                let arr = item?.key_value?.split(",")
                if (arr[0]?.includes('jpg')) {
                  setEditSelectedImageMedia(arr)
                }
                if (arr[0]?.includes('mp4')) {
                  setEditSelectedVideoMedia(arr)
                }
              }
              obj[item?.key_name === "CLASSIFI" ? "CLASSIFICATION" : item?.key_name] = item?.key_value
            })
          }
          // console.log('setting the data', obj);
          if (SectionId_Number === 2) {
            setSec14FormData([...obj_section_14])
          }
          setEditFormData({
            ...editFormData,
            ...obj
          });
        }
      };
      fetchSectionDetails();
    }
  }, [SectionId_Number, isOpen]);


  const returnSec_14_payload = (key) => {
    let obj = sec14FormData?.find((item) => item?.key_name === key);
    return obj?.key_value
  }



  // Dynamic PlayLoad for sectins 
  const createPayload = (id, key) => {
    switch (id) {
      case 1:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null
      case 2:
        return returnSec_14_payload(key)
      case 3:
        return key === "TITLE_1" ? editFormData[key] : key === "TITLE_2" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : null;
      case 4:
        return key === "BTN" ? editFormData[key] : key === "BTN_LINK" ? editFormData[key] : key === "TITLE_1" ? editFormData[key] : key === "TITLE_2" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null;
      case 5:
        return key === "TITLE" ? editFormData[key] : key === "CAMP" ? editFormData[key].toString() : null
      case 6:
        return key === "TITLE" ? editFormData[key] : key === "BLOG" ? editFormData[key].toString() : null;
      case 7:
      case 9:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : null;
      case 10:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null;
      case 11:
        return key === "TITLE_1" ? editFormData[key] : key === "TITLE_2" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null
      case 8:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : null;
      case 12:
        return key === "TITLE_1" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null
      case 13:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : null;
      case 14:
        return key === "TITLE" ? editFormData[key] : "MESSAGE" ? editFormData[key] : null
      case 15:
        return key === "TITLE_1" ? editFormData[key] : key === "PRE_ORDER" ? editFormData[key].toString() : null;
      case 16:
        return key === "SHOW_STICKYICONS" ? editFormData[key].toString() : null;
      default:
        return '';
    }
  };
  const extractSectionMapId = (key) => {
    let keyName = key;
    if (key === "File_Video") {
      keyName = "File"
    }
    let obj = mapId?.find(item => {
      console.log(item?.key_name, keyName)
      if (item?.key_name === keyName) return item;
    })
    return obj ? obj?.section_map_id : -1
  }

  // Api  UpdateSection
  const handleEditSectionSubmit = async (e, key) => {
    e.preventDefault();
    try {
      let payload = {
        section_group: sectionGroupId,
        section_map_id: extractSectionMapId(key),
        key_value: createPayload(SectionId_Number, key),
      };
      const res = await updateSection(token, payload);
      if (res) {
        toast.success("Section updated successfully!");
        clearData();
        onRequestClose();
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to update section.");
    }
  };


  // API fetched  DATA FOR THE DROPDOWN
  const EditfetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setEditAdsDropdown(
      response.data?.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };



  const EdicampaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    seteditCampaignData(
      response?.data?.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };


  //API fetched for single dropdown data 
  const editFetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setEditSingleDropDown(response.data);
  };

  // Api fetched for classifications
  const editFetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    console.log(classifications?.data)
    setEditSectionDropdownData(
      classifications.data.map((option) => ({
        value: option.classification_id,
        label: option.classification_name.toUpperCase(),
      }))
    );
  };

  const sec14_ImageGalleryOpen = (rowIndex) => {
    setSection14ImgId(rowIndex);
    setIsImageGalleryOpen(true);
    setEditIsImageGalleryOpen(true);
  };

  const sec14HandleOnChange = (event, index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: event.target.value
    };
    setSec14FormData(updatedFormData);
  };

  const sec14_ImageGalleryHandle = (mediaUrl) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[section14ImgId] = {
      ...updatedFormData[section14ImgId],
      key_value: mediaUrl
    };
    setSec14FormData(updatedFormData);
  };
  const sec14_handleDeleteMedia = (index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index].key_value = "";
    setSec14FormData(updatedFormData);
  };





  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Edit Section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "1500px",
            minWidth: "1500px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
            border: "2px solid #63E6BE",
          },
        }}
      >
        <div className="modal-header">
          <h2>Edit Section</h2>
          <button
            onClick={() => {
              onRequestClose();
              clearData();
            }}
          >
            Close
          </button>
        </div>
        {/* Section Input fields */}
        <form>
          {EditerrorMessage && <div className="error-message">{EditerrorMessage}</div>}
          {[1].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="ed          console.log(transform)itAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

            </div>
          )}
          {/* {section 2} */}
          {[2].includes(parseInt(SectionId_Number)) && (
            <div style={{ paddingBottom: '20px' }}>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput1`}>Title - 1</label>
                    <input
                      id={`titleInput1`}
                      type="text"
                      name={sec14FormData[0]?.key_name}
                      value={sec14FormData[0]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 0)}
                      className="form-control"
                      placeholder={`Enter title 1`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[0]?.key_name)}>
                      Edit
                    </button>
                  </div>

                  <div>
                    <label htmlFor="titleInput">Description </label>
                    <textarea
                      id="titleInput2"
                      name={sec14FormData[1]?.key_name}
                      value={sec14FormData[1]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 1)}
                      className="form-control"
                      placeholder="type here "
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[1]?.key_name)}>
                      Edit Description
                    </button>
                  </div>
                </div>


                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(2)}
                  >
                    Image Gallery 1
                  </button>
                </div>


                {sec14FormData[2]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[2]?.key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(2)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[2]?.key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>


              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput2`}>Title 2</label>
                    <input
                      id={`titleInput2`}
                      type="text"
                      name={sec14FormData[3]?.key_name}
                      value={sec14FormData[3]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 3)}
                      className="form-control"
                      placeholder={`Enter title 2`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[3]?.key_name)}>
                      Edit
                    </button>
                  </div>

                  <div>
                    <label htmlFor="titleInput">Description </label>
                    <textarea
                      id="titleInput2"
                      name={sec14FormData[4]?.key_name}
                      value={sec14FormData[4]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 4)}
                      className="form-control"
                      placeholder="type here "
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[4]?.key_name)}>
                      Edit Description
                    </button>
                  </div>
                </div>

                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(5)}
                  >
                    Image Gallery 2
                  </button>
                </div>
                {sec14FormData[5]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[5]?.key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(5)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[5]?.key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput3`}>Title 3</label>
                    <input
                      id={`titleInput3`}
                      type="text"
                      name={sec14FormData[6]?.key_name}
                      value={sec14FormData[6]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 6)}
                      className="form-control"
                      placeholder={`Enter title 3`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[6]?.key_name)}>
                      Edit
                    </button>
                  </div>
                  <div>
                    <label htmlFor="titleInput">Description </label>
                    <textarea
                      id="titleInput2"
                      name={sec14FormData[7]?.key_name}
                      value={sec14FormData[7]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 7)}
                      className="form-control"
                      placeholder="type here "
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[7]?.key_name)}>
                      Edit Description
                    </button>
                  </div>
                </div>

                {/* >>>>> */}
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(8)}
                  >
                    Image Gallery 3
                  </button>
                </div>
                {sec14FormData[8]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[8]?.key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(8)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[8]?.key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput4`}>Title 4</label>
                    <input
                      id={`titleInput4`}
                      type="text"
                      name={sec14FormData[9]?.key_name}
                      value={sec14FormData[9]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 9)}
                      className="form-control"
                      placeholder={`Enter title 4`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[9]?.key_name)}>
                      Edit
                    </button>
                  </div>
                  <div>
                    <label htmlFor="titleInput">Description </label>
                    <textarea
                      id="titleInput2"
                      name={sec14FormData[10]?.key_name}
                      value={sec14FormData[10]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 10)}
                      className="form-control"
                      placeholder="type here "
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[10]?.key_name)}>
                      Edit Description
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(11)}
                  >
                    Image Gallery 4
                  </button>
                </div>
                {sec14FormData[11]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[11]?.key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(11)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[11]?.key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {[3].includes(SectionId_Number) && (
            <div>
              {/* Title 1:  */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>
              {/* Title 2:  */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>
              {/* classifications:  */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[4].includes(parseInt(SectionId_Number)) && (
            <div>

              {/* {Title_1} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>
              {/* {Title_2} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>

              {/* {Button Name} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">  Button Name:  </label>
                <input
                  id="titleInput"
                  type="text"
                  name="BTN"
                  value={editFormData.BTN}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BTN")}>
                  Edit
                </button>
              </div>
              {/* 
             {button link} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Button link: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="BTN_LINK"
                  value={editFormData.BTN_LINK}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BTN_LINK")}>
                  Edit
                </button>
              </div>


              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 5) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 5 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

            </div>
          )}
          {[5].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* CAMPAIGN:  */}
              <div className="dropdown-container">
                <label htmlFor="Campaign">CAMPAIGN</label>
                <Select
                  id="CampaignDropdown"
                  name="CAMP"
                  value={editCampaignData.filter(
                    (option) => option.value === parseInt(editFormData.CAMP)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CAMP: selectedOption.value,
                    }))
                  }
                  options={editCampaignData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CAMP")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[6].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container-fluid form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* BLOG */}
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Blog Toggele</label>
                <ToggleSwitch
                  checked={editFormData.BLOG == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      BLOG: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BLOG")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[7].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container-fluid form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* classifications:  */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[8].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 1) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 2 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

              {/* classifications:  */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>

            </div>
          )}
          {[9].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* classifications:  */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[10].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>



              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 1) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 2 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {[11].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 2: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  // onChange={handleChange}
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 3) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 3 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>


            </div>
          )}
          {[12].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title 1: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 1) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 2 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {[13].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              {/* classifications:  */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[14].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>


              {/* MESSAGE */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">MESSAGE: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="MESSAGE"
                  value={editFormData.MESSAGE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "MESSAGE")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[15].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              {/* PRE_ORDER */}
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Pre-order Toggele</label>
                <ToggleSwitch
                  checked={editFormData.PRE_ORDER == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      PRE_ORDER: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "PRE_ORDER")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[16].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              {/* SHOW_STICKYICONS */}
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2"> SHOW STICKYICONS</label>
                <ToggleSwitch
                  checked={editFormData.SHOW_STICKYICONS == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      SHOW_STICKYICONS: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "SHOW_STICKYICONS")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          <div className="media-preview-container">
            {editSelectedImageMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Images</h3>
                <div className="media-items">
                  {editSelectedImageMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <img
                        src={AppUrl + mediaUrl}
                        alt="Selected"
                        width={"250px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("image", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
            {editSelectedVideoMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Videos</h3>
                <div className="media-items">
                  {editSelectedVideoMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <video
                        src={AppUrl + mediaUrl}
                        controls
                        width={"250px"}
                        height={"200px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("video", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File_Video")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
        <ImageGallery
          isOpen={EditisImageGalleryOpen}
          onRequestClose={() => setEditIsImageGalleryOpen(false)}
          onMediaSelect={editHandleImageMediaSelect}
          selectedMedia={editSelectedImageMedia}
          setSelectedMedia={setEditSelectedImageMedia}
          limit={3}
          sectionId={sectionId}
          sec14_ImageGalleryHandle={sec14_ImageGalleryHandle}
          SectionId_Number={SectionId_Number}
        />
        <VideoGallery
          isOpen={EditsVideoGalleryOpen}
          onRequestClose={() => seteditIsVideoGalleryOpen(false)}
          onMediaSelect={handleVideoMediaSelect}
          selectedMedia={editSelectedVideoMedia}
          setSelectedMedia={setEditSelectedVideoMedia}
          limit={limit}
        />
      </Modal>
    </>
  );
};
export default SectionEditModel;






