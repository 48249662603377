import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faEllipsisVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { allProducts } from "../../../service/apiService";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../../assets/images/noimage.jpg";
import { Spinner } from "react-bootstrap";

const ProductList = () => {
  const navigate = useNavigate();
  const [ProductList, setProduct] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const AppUrl = "https://folcon.stylefitz.in/api/files/";
  const itemsPerPage = 8;

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("admin_token");
      const responseData = await allProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setProduct(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (product_id) => {
    navigate(`/admin/product-edit`, { state: { product_id } });
  };

  const handleView = (product_id) => {
    navigate(`/admin/product-view`, { state: { product_id } });
  };

  const columns = [
    {
      field: "sno",
      header: "S.No",
      body: (rowData) => <span className="fade-text">{rowData.sno}</span>,
    },
    {
      field: "product_name",
      header: "Product Name",
      body: (rowData) => (
        <span>
          <strong>{rowData.product_name}</strong> <br />SKU: {rowData.sku}
        </span>
      ),
    },
    {
      field: "quantity",
      header: "Quantity",
      body: (rowData) => (
        <span className="fade-text">
          {rowData.minimum_stock_warning && rowData.quantity <= rowData.minimum_stock_warning ? (
            <span style={{ color: "red" }}>
              {rowData.quantity} - Minimum Stock Warning
            </span>
          ) : (
            rowData.quantity
          )}
        </span>
      ),
    },
    {
      field: "thumnail_image",
      header: "Image",
      body: (rowData) => (
        <img
          src={rowData.thumnail_image.startsWith(AppUrl) ? rowData.thumnail_image : `${AppUrl}${rowData.thumnail_image}` || noImage}
          width={50}
          height={50}
          alt="Product"
        />
      ),
    },
    {
      field: "slug",
      header: "Slug",
      body: (rowData) => <span className="fade-text">{rowData.slug}</span>,
    },
    {
      field: "is_active",
      header: "Status",
      body: (rowData) => (
        <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={() => handleEdit(rowData.product_id)}>
                Edit info
              </a>
              <a className="dropdown-item" onClick={() => handleView(rowData.product_id)}>
                View info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const filteredProducts = ProductList?.filter((product) =>
    product.product_name?.toLowerCase().includes(globalFilter.toLowerCase())
  );

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);
  const currentProductList = filteredProducts
    .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)
    .map((product, index) => ({
      ...product,
      sno: pageNumber * itemsPerPage + index + 1,
    }));

  console.log(currentProductList)
  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);

  return (

    <>
      <main id="main" className={`main ${loading ? "loading" : ""}`}>
        {loading && (
          <div className="loading-overlay">
            <Spinner animation="border" role="status" className="large-spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {!loading && (
          <>
            <div className="pagetitle">
              <h1>Product campaign Create</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">Product campaign</li>
                  <li className="breadcrumb-item active">Create</li>
                </ol>
              </nav>
            </div>
            <br />
            <div className="pagetitle">
              <div>
                <button onClick={() => navigate('/admin/product-create')} className="btn btn-primary">
                  <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
                  Create Products
                </button>
              </div>
              <div className="search-bar">
                <span className="p-input-icon-left">
                  <input
                    type="search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="custom-input"
                  />
                </span>
              </div>
            </div>

            <section className="section">
              {filteredProducts.length > 0 ? (
                <>
                  <DataTable
                    value={currentProductList}
                    globalFilter={globalFilter}
                    emptyMessage="No Products records found"
                    className="react-data-table"
                  >
                    {columns.map((col, i) => (
                      <Column key={i} field={col.field} header={col.header} body={col.body} />
                    ))}
                  </DataTable>
                  <div className="pagetitle">
                    <div className="results-info">
                      Showing {startItem} - {endItem} of {filteredProducts?.length} results
                    </div>
                    <ReactPaginate
                      previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                      nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </>
              ) : (
                <div className="no-data-message">No data available</div>
              )}
            </section>
          </>
        )}
      </main>
    </>

  );
};

export default ProductList;
