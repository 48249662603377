import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CategoriesDropDown,
  GetCategoryList,
  MediaList,
} from "../../../service/apiService";
import {
  faEllipsisVertical,
  faCircleXmark,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { ValidateCategoryName } from "../../../validation/CustomValidation";
import { CustomValidationError } from "../../../Helper/Helper";
import { useNavigate } from "react-router-dom";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import UploadImage from "../../../assets/images/defimg.jpeg";
import noImage from "../../../assets/images/noimage.jpg";

const CategoryList = () => {
  const [Categories, setCategories] = useState([]);
  console.log(Categories)
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedcategoryId, setSelectedCategoryId] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [categorieImage, setcategorieImage] = useState(null);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [formDataUrl, setformDataUrl] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isColorPickerDisabled, setIsColorPickerDisabled] = useState(false);
  const [categoryDropdownList, setCategoryDropdown] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [categorieImageUrl, setcategorieImageUrl] = useState(null);
  const [deletedImage, setDeletedImage] = useState(false);
  // const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.stylefitz.in/api/files/";
  const ListUrl = "https://folcon.stylefitz.in/api/";
  const [imageUrl, setImageUrl] = useState([]);
  // const token = localStorage.getItem("admin_token");
  const [categoryImage, setCategoryImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages?.filter((i) => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setCategoryImage(imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length >0){
      response.then((response) => {
        const imageUrls = response.data?.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      //  }
    }
  }, [token]);

  const [categoryformData, setCategoryFormData] = useState({
    category_name: "",
    slug: "",
    description: "",
    is_active: 1,
    category_image: "",
    color_code: "",
    parent_id: "",
  });
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;

  const filteredProducts = Categories?.filter((Categorie) =>
    Categorie.category_name?.toLowerCase().includes(globalFilter.toLowerCase())
  );


  const pageCount = Math.ceil(Categories.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };


  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);



  const currentCategories = Categories.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  useEffect(() => {
    fetchData();
    dropDownData();
  }, []);
  const [validationErrors, setValidationErrors] = useState({
    category_name: "",
  });

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const response = await GetCategoryList(token);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setCategoryFormData({
      ...categoryformData,
      color_code: color,
    });
  };

  const dropDownData = () => {
    const categoryDropdown = CategoriesDropDown(token);
    categoryDropdown
      .then((response) => {
        setCategoryDropdown(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = async (categoryId) => {
    console.log("dd")
    try {
      const token = localStorage.getItem("admin_token");
      console.log(token)
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await axios.get(
        `https://folcon.stylefitz.in/admin/get-category/${categoryId}`,
        config
      );
      const responseData = response.data;

      setCategoryFormData({
        category_name: responseData.data.category_name,
        slug: responseData.data.slug,
        description: responseData.data.description,
        category_image: responseData.data.category_image,
        is_active: responseData.data.is_active,
        parent_id: responseData.data.parent_id,
        color_code: responseData.data.color_code,
      });
      const previewImageUrl = responseData.data.category_image
        ? AppUrl.concat(responseData.data.category_image)
        : null;
      setPreviewImage(previewImageUrl);
      // alert(responseData.data.category_image);
      // if (responseData.data.category_image) {
      //  // setcategorieImage(`${appBaseUrl}${responseData.data.category_image}`);
      //   setcategorieImage(responseData.data.category_image);
      //   setcategorieImageUrl(responseData.data.category_image);
      //   setformDataUrl(responseData.data.category_image);
      // }else{
      //   setcategorieImageUrl(null);
      // }
      if (responseData.data.color_code) {
        setSelectedColor(responseData.data.color_code);
      }

      setSelectedCategoryId(categoryId);
      openEditModal();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };
  const deleteImage = () => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      categoryformData.category_image = null;
      setformDataUrl(null);
      setSelectedFile(null);
      setcategorieImage(null);
      setShowTrashIcon(false);
      setDeletedImage(true);
      setPreviewImage(null);
      setSelectedImages([]);
      setCategoryImage(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const categoryNameError = ValidateCategoryName(
        categoryformData.category_name
      );
      setValidationErrors({
        category_name: categoryNameError,
      });
      const parent_id = parseInt(categoryformData.parent_id);
      const data = {
        ...categoryformData,
        category_id: selectedcategoryId,
        category_image: categoryImage,
        parent_id: parent_id,
      };

      const newImageUrl = categoryImage
        ? categoryImage
        : categoryformData.category_image;
      data.category_image = newImageUrl;
      if (deletedImage === true) {
        // alert("deletedImage");
        data.category_image = null;
        setDeletedImage(false);
      }
      if (!categoryNameError) {
        const response = await axios.post(
          "https://folcon.stylefitz.in/admin/update-category",
          data,
          config
        );

        const responseData = response.data;

        if (responseData.status === "success") {
          fetchData();
          toast.success("Category updated successfully!");
          closeEditModal();
          setcategorieImage(null);
          setSelectedFile(null);
          imageUrl = null;
        } else {
          toast.error("Category update failed. Please try again.");
        }

      }
    } catch (error) {
      console.error("Error updating Category:", error);
    }

  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "category_name") {
      const categoryName = value.replace(/[^a-zA-Z\s]/g, "");
      setCategoryFormData((prevData) => ({
        ...prevData,
        [id]: categoryName,
        slug: generateSlug(categoryName),
      }));
    } else if (id === "parent_id") {
      const selectedCategory = categoryDropdownList.find(
        (category) => category.id === parseInt(value)
      );
      if (selectedCategory) {
        setCategoryFormData((prevData) => ({
          ...prevData,
          [id]: value,
          color_code: selectedCategory.color_code,
        }));
        setSelectedColor(selectedCategory.color_code);
        setIsColorPickerDisabled(true);
      } else {
        setCategoryFormData((prevData) => ({
          ...prevData,
          [id]: value,
          color_code: "",
        }));
        setSelectedColor("#000000");
        setIsColorPickerDisabled(false);
      }
    } else {
      setCategoryFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };
  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setCategoryFormData({
      ...categoryformData,
      is_active: activeValue,
    });
  };

  const header = (
    <div className="table-header">
    </div>
  );

  const handleDelete = async (categoryId) => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const data = {
        category_id: categoryId,
      };

      const response = await axios.post(
        "https://folcon.stylefitz.in/admin/delete-category",
        data,
        config
      );

      const responseData = response.data;

      if (responseData.status === "success") {
        toast.success("category deleted successfully!");
        fetchData();
      } else {
        toast.error("Category deletion failed. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setcategorieImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const columns = [
    { field: "id", header: "ID", sortable: true },
    { field: "category_name", header: "CategoryName", sortable: true },
    {
      field: "image_path",
      header: "Image",

      body: (rowData) => (
        <img
          src={
            rowData.category_image ? AppUrl + rowData.category_image : noImage
          }
          width={100}
          height={100}
          alt="category"
        />
      ),
    },
    { field: "slug", header: "Slug" },
    {
      field: "is_active",
      sortable: true,
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item edit-icon"
                onClick={() => handleEdit(rowData.id)}
                style={{ cursor: "pointer" }}
              >
                Edit info
              </a>
              <a
                className="dropdown-item text-danger"
                onClick={() => handleDelete(rowData.id)}
                style={{ cursor: "pointer" }}
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];



  return (

    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Category List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Category</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <br />
      <div className="pagetitle">
        <div>
          <button
            onClick={() => navigate("/admin/categories/create")}
            className="btn btn-primary"
          >
            Create Categories
          </button>
        </div>
        <div className="search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <input
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="custom-input"
            />
          </span>
        </div>
      </div>
      <ReactDataTable
        data={currentCategories}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        header={header}
        emptyMessage="No Category records found"
        columns={columns}
        className="react-data-table"
      />
      <div className="pagination-container">
        <div className="results-info">
          Showing {startItem} - {endItem} of {filteredProducts?.length} results
        </div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
          nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Category"
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            maxWidth: "90%",
            maxHeight: "90%",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="container" style={{ width: "900px" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Edit Category
              </h2>
            </div>
            <div className="col-auto">
              <button
                onClick={closeEditModal}
                style={{
                  fontSize: "30px",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#63E6BE" }}
                />
              </button>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="form-label">
                {" "}
                Name
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="category_name"
                value={categoryformData.category_name}
                onChange={handleChange}
              />
              <CustomValidationError error={validationErrors.category_name} />
            </div>
            <div className="mb-4">
              <label htmlFor="slug" className="form-label">
                {" "}
                Slug
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control read-only"
                id="slug"
                value={categoryformData.slug}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="description"
                value={categoryformData.description}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="toggle" className="form-label">
                Status
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="toggle"
                  checked={categoryformData.is_active === 1}
                  onChange={handleToggleChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="parent_id" className="col-sm-2 col-form-label">
                Parent Category
              </label>
              <div className="col-sm-4">
                <select
                  className="select-active"
                  id="parent_id"
                  name="parent_id"
                  onChange={handleChange}
                  value={categoryformData.parent_id}
                >
                  <option value="">No Parent</option>
                  {categoryDropdownList && categoryDropdownList.length > 0 ? (
                    categoryDropdownList?.map((category) => (
                      <option
                        key={category.id}
                        value={category.id}
                        selected={categoryformData.parent_id === category.id}
                      >
                        {category.category_name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="colorPicker" className="col-sm-2 col-form-label">
                Color
              </label>
              <div className="col-sm-4">
                <div className="input-group">
                  <input
                    id="colorPicker"
                    type="text"
                    name="color_code"
                    value={selectedColor}
                    onChange={(e) => handleColorChange(e.target.value)}
                    disabled={isColorPickerDisabled}
                    className="form-control"
                    style={{
                      border: `2px solid ${selectedColor}`,
                      borderRadius: "5px",
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <span
                    className="input-group-text"
                    style={{
                      border: `2px solid ${selectedColor}`,
                      borderRadius: "5px",
                      backgroundColor: selectedColor,
                      color: "#000",
                      borderLeft: "none",
                      padding: "0px 5px",
                      width: "192px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label>Preview</label>

              <br />
              {previewImage ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    //  src={categorieImage}
                    src={previewImage}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      width: "200px",
                      height: "200px",
                    }}
                  />

                  <button
                    type="button"
                    onMouseEnter={() => setShowTrashIcon(true)}
                    onMouseLeave={() => setShowTrashIcon(false)}
                    onClick={() => deleteImage()}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ) : (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={UploadImage}
                    alt="Alt"
                    style={{
                      maxWidth: "100%",
                      width: "350px",
                      height: "200px",
                    }}
                  />
                </div>
              )}

              <br></br>
              <button
                type="button"
                className="btn btn-primary mt-2 btn-sm btn-block"
                onClick={openModal}
              >
                Select Image
              </button>
            </div>

            <SingleImageSelect
              isOpen={modalIsOpen}
              handleClose={closeModal}
              handleImageClick={handleImageClick}
              selectedImages={selectedImages}
              imageUrl={imageUrl}
              token={token}
              setImageUrl={setImageUrl}
            />

            <br />
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        </div>
      </Modal>
    </main>


  );
};

export default CategoryList;
