import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const BannerType = () => {
    const navigate = useNavigate();

    const [bannerTypeName, setBannerTypeName] = useState({
        banner_type_name: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBannerTypeName({ ...bannerTypeName, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!bannerTypeName.banner_type_name) {
            newErrors.banner_type_name = 'Banner Type Name is required';
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            toast.error('Please fill in all required fields');
            return;
        }

        const token = localStorage.getItem('admin_token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
        };

        try {
            const createPayload = { ...bannerTypeName };
            const createResponse = await axios.post('https://folcon.stylefitz.in/admin/create-banner-type', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('Banner Type created successfully!');
                setBannerTypeName({ banner_type_name: '' });
                navigate('/admin/banner-type-list');
            } else {
                toast.error('Banner Type creation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error creating banner type:', error);
            toast.error('Banner Type creation failed. Please try again.');
        }
    };

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Banner Type</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Settings</li>
                        <li className="breadcrumb-item active">Banner Type</li>
                    </ol>
                </nav>
            </div>
            <section className="section">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <form className="row g-3" onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="banner_type_name" className="col-sm-2 col-form-label">Banner Type Name</label>
                                        <div className="col-sm-4">
                                            <input
                                                id="banner_type_name"
                                                type="text"
                                                name="banner_type_name"
                                                placeholder="Type here"
                                                className="form-control"
                                                value={bannerTypeName.banner_type_name}
                                                onChange={handleChange}
                                            />
                                            {errors.banner_type_name && (
                                                <small className="text-danger">{errors.banner_type_name}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </main>
    );
};

export default BannerType;
