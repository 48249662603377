import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteSection,
  pageSectionList,
  saveKanbanChangesAPICall,
} from "../../../../service/apiService";
import SectionEditModel from "../SectionEdit/SectionEditModel";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ItemTypes = {
  SECTION: "section",
};

const token = localStorage.getItem("admin_token");

const Section = ({ section, index, moveSection, setRefreshData }) => {
  const [isActive, setIsActive] = useState(section.isActive);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [{ isDragging }, ref] = useDrag({
    type: ItemTypes.SECTION,
    item: { index, id: section.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //edit icon
  const handleEdit = () => {
    setIsEditModalOpen(true);
  };


  const handleDelete = async () => {
    const result = await Swal.fire({
      title: 'Want to delete the Section ?',
      text: "This action will delete the section permanently !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes ,delete it!',
    });

    if (result.isConfirmed) {
      await Swal.fire({
        title: 'Confirm Delete',
        text: "Are you really sure you want to delete this section?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
      }).then(async (secondResult) => {
        if (secondResult.isConfirmed) {
          const payload = {
            page_id: parseInt(localStorage.getItem("pageid")),
            section_id: parseInt(section.id.match(/\d+/)[0]),
            section_group: parseInt(section.section_group),
          };

          try {
            const response = await deleteSection(token, payload);
            if (response.status_code === 200) {
              await Swal.fire(
                'Deleted!',
                'Your section has been deleted.',
                'success'
              );
              setRefreshData(prev => prev + 1);
            } else {
              toast.error("Section deletion failed. Please try again.");
            }
          } catch (error) {
            toast.error("Section deletion failed. Please try again.");
          }
        }
      });
    }
  };


  return (
    <div
      ref={ref}
      style={{
        padding: 10,
        border: "1px solid #ccc",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: isDragging ? "#d3d3d3" : "#fff",
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "move",
      }}
    >
      <span style={{ flex: 1, margin: 0 }}>{section.title}</span>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <button className="button-bg" onClick={handleEdit}>
          <FontAwesomeIcon icon={faPenToSquare} title="Edit" />
        </button>
        <button className="button-bg" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashCanArrowUp} title="Delete" />
        </button>
      </div>
      {/* Edit Modal */}
      <SectionEditModel
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        sectionId={section.id}
        sectionGroupId={section.section_group}
        // section_mapId={section.}
        onSuccess={isActive}
      />
    </div>
  );
};

const KanbanBoard = ({ success }) => {
  const [sections, setSections] = useState([]);
  const [refreshData, setRefreshData] = useState(0);


  const moveSection = (dragIndex, hoverIndex) => {
    const draggedSection = sections[dragIndex];
    const newSections = [...sections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, draggedSection);
    newSections.forEach((section, index) => {
      section.index = index;
    });

    setSections(newSections);
  };

  // *********************************
  useEffect(() => {


    const fetchSections = async () => {
      const pageid = localStorage.getItem("pageid");
      const Api_token = localStorage.getItem("admin_token");
      const response = await pageSectionList(Api_token, pageid);
      if (response?.data) {
        const transformedData = transformData(response.data);
        setSections(transformedData);
      } else {
        setSections([]);
      }
    };
    fetchSections();
  }, [refreshData, success]);

  const transformData = (data) => {
    return data.map((section, index) => ({
      id: `section${section.section_id}_${index}`,
      title: section.section_name.toUpperCase(),
      isActive: section.is_active,
      section_group: section.section_group,
    }));
  };

  const saveKanbanChanges = async () => {
    const pageid = localStorage.getItem("pageid");
    const updatedKanbanSections = sections.map((item, index) => ({
      section_group: item.section_group,
      place_order: index + 1,
    }));
    const payload = {
      page_id: parseInt(pageid),
      section_place: updatedKanbanSections,
    };
    try {
      const response = await saveKanbanChangesAPICall(token, payload);
      const status = await response.status_code;
      console.log("status", status);
      if (status === 200) {
        toast.success("Section place changed successfully !");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div >
      <div
        style={{
          overflowY: 'auto', // vertical scrollbar
          height: '600px', // or set a specific height if needed
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            borderBottom: "2px solid #ccc",
          }}
        >
          <strong style={{ flex: 1 }}>Section Name</strong>
          <div style={{ display: "flex", gap: "7px" }}>
            <strong>Edit |</strong>
            <strong>View |</strong>
            <strong>Delete |</strong>
            <strong>Status </strong>
          </div>
        </div>

        {sections.map((section, index) => (
          <SectionWrapper
            key={section.id}
            index={index}
            section={section}
            moveSection={moveSection}
            setRefreshData={setRefreshData}
          />
        ))}

      </div>
      <br />
      <button onClick={saveKanbanChanges}>Save Changes</button>
    </div>

  );
};

const SectionWrapper = ({ section, index, moveSection, setRefreshData }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.SECTION,
    hover: (item) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  return (
    <div ref={drop}>
      <Section section={section} index={index} moveSection={moveSection} setRefreshData={setRefreshData} />
    </div>
  );
};

export default KanbanBoard;
